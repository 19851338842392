import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Button from './Button'

const BlogArchiveFilters = props => {
  const data = useStaticQuery(graphql`
    query BlogArchiveFilterQuery {
        tags: allStoryblokEntry(filter: {field_component: {eq: "post"}}) {
            group(field: tag_list) {
                fieldValue
            }
        }
    }
  `)

  return (
    <div className="flex flex-row flex-wrap gap-x-4 gap-y-4">
        <Button
            to="summit-blog"
            className=""
            type="pink"
            activeClassName="!bg-lightblue"
        >
        All posts
        </Button>
        {data.tags.group.map((item) => {
            return (
                <Button
                    to={`summit-blog/category/${item.fieldValue.replace(/\s/g, "-").toLowerCase()}/`}
                    className=""
                    type="pink"
                    activeClassName="!bg-lightblue"
                    key={item.fieldValue}
                >
                {item.fieldValue}
                </Button>
            )
        })}
    </div>
  )
}

export default BlogArchiveFilters

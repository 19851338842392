import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import TwoColumnWithSignUpItem from 'src/components/TwoColumnWithSignUpItem'
import Hero from 'src/components/TBB/Heros/Hero'
import BlogArchiveFilters from './BlogArchiveFilters'

const BlogArchive = ({ posts, numPages, currentPage, tagSlug }) => {

  const { settings } = useStaticQuery(graphql`
  query BlogSettings {
    settings: allStoryblokEntry(filter: {field_component: {eq: "settings"}}) {
      edges {
        node {
          name
          full_slug
          content
        }
      }
    }
  }
  `)
  let content = JSON.parse(settings.edges[0].node.content)
  let parsedSetting = Object.assign({}, content, {content: content})

  return (
    <>
        <Hero 
            blok={{
                title: `Knowledge Centre ${tagSlug ? ` - ${tagSlug}` : ''}`,
                colour: 'darkblue',
                button_text: parsedSetting.blog_description,
                button_target: 'test'
            }}
        />
        <section className="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
            <div className="relative mx-auto max-w-7xl">
                <BlogArchiveFilters/>
                <div className="mt-6 pt-10 grid gap-12 md:grid-cols-2 lg:grid-cols-3 lg:gap-x-16 lg:gap-y-12">
                    {posts.map((item, index) => {
                        return(
                            <TwoColumnWithSignUpItem {...item.node} key={index} isArchive/>
                        ) 
                    })}
                </div>
            </div>
        </section>
        {numPages > 1 &&
            <nav aria-label="Page navigation" className="py-12">
                <ul className="text-center space-x-2">
                {Array.from({ length: numPages }, (_, i) => (
                    <li className="inline-block" key={i}>
                    <Link
                        className={`flex justify-center items-center hover:text-pink duration-300 ${
                        currentPage === i + 1
                            ? "text-pink"
                            : "text-darkblue"
                        } text-base h-6 w-6 font-display`}
                        key={`pagination-number${i + 1}`}
                        to={`/summit-blog/${tagSlug ? `category/${tagSlug}/` : ''}${i === 0 ? "" : `${i + 1}/`}`}
                    >
                        <span>{i + 1}</span>
                    </Link>
                    </li>
                ))}
                </ul>
            </nav>    
        }
    </>
  )
}

export default BlogArchive
